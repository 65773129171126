var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-container',{ref:"container"},[_c('v-row',[_c('v-col',{staticClass:"py-2 text-right",attrs:{"cols":"12"}},[_c('v-btn-toggle',{attrs:{"borderless":"","dense":""},model:{value:(_vm.viewType),callback:function ($$v) {_vm.viewType=$$v},expression:"viewType"}},[_c('v-btn',{attrs:{"value":"chips","dense":""}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("CHIPS")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-widgets-outline ")])],1),_c('v-btn',{attrs:{"value":"table","dense":""}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("TABLE")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-table ")])],1)],1),_c('o-button',{staticClass:"pl-5",attrs:{"color":"primary","icon":"mdi-sync","type":"right-label-icon","label":"Restart Fluent"},on:{"on-click":function($event){return _vm.restartFluent()}}})],1),(_vm.viewType === 'chips')?_vm._l((_vm.groups),function(group,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_vm._v(" "+_vm._s(group)+" ")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.filterByGroup(_vm.subscriptions, group)),function(subscription,j){return _c('v-col',{key:j,attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"3","xl":"2"}},[(subscription.group === group)?[_c('Subscription',{attrs:{"query":subscription.query},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('v-expand-transition',[_c('status-card-v2',{key:(data === null) ? null : data.serviceStatus.ts,attrs:{"title":subscription.name,"enable-text":data === null ? 'Disabled' : data.serviceStatus.active,"status-text":data === null ? 'Stopped' : data.serviceStatus.status,"time-text":data === null ? '' : data.serviceStatus.ts,"queue-id":subscription.id}})],1)]}}],null,true)})]:_vm._e()],2)}),1)],1)],1)],1)}):_vm._e(),(_vm.viewType === 'table')?[_c('v-col',{attrs:{"cols":"12"}},[_c('o-table',{ref:"table",attrs:{"type":"array","data":_vm.tableData,"headers":[
            {
              text: 'GROUP',
              align: 'start',
              sortable: false,
              value: 'group',
              type: 'text',
            },
            {
              text: 'NAME',
              align: 'start',
              sortable: false,
              value: 'name',
              type: 'text',
            },
            {
              text: 'ACTIVE',
              align: 'middle',
              sortable: false,
              value: 'active',
              type: 'slot',
            },
            {
              text: 'STATUS',
              align: 'middle',
              sortable: false,
              value: 'status',
              type: 'slot',
            },
          ]},scopedSlots:_vm._u([{key:"slot",fn:function(row){return [(row.prop.key === 'active' || row.prop.key === 'status')?_c('div',[_c('Subscription',{attrs:{"query":row.prop.item.query},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('span',{class:data &&
                      ['Enabled', 'Running'].includes(
                        data.serviceStatus[row.prop.key]
                      )
                      ? 'text-success'
                      : 'text-warning'},[_vm._v(" "+_vm._s(data === null ? "Disabled" : data.serviceStatus[row.prop.key])+" ")])]}}],null,true)})],1):_vm._e()]}}],null,false,1464320844)})],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }