<template>
  <DashboardServiceStatus dialog-name="Service" />
</template>
<script>
import DashboardServiceStatus from '@/views/app/dashboard/ServiceStatus'

export default {
  name: 'ServiceStatusWrapper',
  components: {
    DashboardServiceStatus
  }
}
</script>
