<template>
  <div>
    <base-card :class="getCardColor(false)">
      <v-card-text class="d-flex align-center h-full pb-0">
        <div
          class="
            d-flex
            flex-grow-1
            justify-space-between
            align-center
            flex-wrap
          "
        >
          <div>
            <p class="ma-1 white--text">
              {{ title }}
            </p>
            <div class="text-h5 white--text ma-0 mr-1">
              <v-chip
                class="ma-1 elevation-4"
                color="white"
                small
                label
                outlined
              >
                {{ enableText }}
              </v-chip>
              <v-chip
                class="ma-1 elevation-4"
                color="white"
                small
                label
                outlined
              >
                {{ statusText }}
              </v-chip>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-subtitle class="mt-1 pt-0 pb-0">
        <p class="text-caption text-end mb-0 text--lighten-4">
          {{ timeLocal(timeText) }}
        </p>
      </v-card-subtitle>
      <v-card-actions>
        <Subscription
          v-slot="{ data }"
          :query="serviceQueueMessage(queueId)"
          class="justify-space-around mx-auto"
        >
          <v-fade-transition mode="out-in">
            <v-btn
              :key="(data === null) ? null : data.serviceQueueStatus.queues.queued"
              small
              depressed
              :class="['ma-1', (data === null) ? '' : (data.serviceQueueStatus.queues.queued > 0 ? 'info--text': '')]"
            >
              Queued {{ (data === null) ? '0' : data.serviceQueueStatus.queues.queued }}
            </v-btn>
          </v-fade-transition>
          <v-fade-transition mode="out-in">
            <v-btn
              :key="(data === null) ? null : data.serviceQueueStatus.queues.processing"
              small
              depressed
              :class="['ma-1', (data === null) ? '' : (data.serviceQueueStatus.queues.processing > 0 ? 'success--text': '')]"
            >
              Processing {{ (data === null) ? '0' : data.serviceQueueStatus.queues.processing }}
            </v-btn>
          </v-fade-transition>
          <v-fade-transition mode="out-in">
            <v-btn
              :key="(data === null) ? null : data.serviceQueueStatus.queues.failed"
              small
              depressed
              :class="['ma-1', (data === null) ? '' : (data.serviceQueueStatus.queues.failed > 0 ? 'danger--text': '')]"
              @click="goTo((data === null) ? '0' : data.serviceQueueStatus.queues.failed)"
            >
              Failed {{ (data === null) ? '0' : data.serviceQueueStatus.queues.failed }}
            </v-btn>
          </v-fade-transition>
        </Subscription>
      </v-card-actions>
    </base-card>
  </div>
</template>
<script>
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en'

// TimeAgo.addDefaultLocale(en)
// const timeAgo = new TimeAgo('en-US')

import { Subscription } from "vue-gql";

export default {
  components: {
    Subscription,
  },
  props: {
    title: {
      type: String,
      default: "None",
    },
    enableText: {
      type: String,
      default: "Enabled",
    },
    statusText: {
      type: String,
      default: "Stopped",
    },
    queueIcon: {
      type: String,
      default: "mdi-numeric-0-circle-outline",
    },
    timeText: {
      type: String,
      default: "",
    },
    queueId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      serviceQueueMessage(id) {
        const stmt = `
        subscription {
            serviceQueueStatus (id: "${id}") {
                id,
                queues
            }
        }
        `
        return stmt
      },
    }
  },
  methods: {
    getCardColor(icon = false) {
      if (this.enableText === "Enabled" && this.statusText === "Running") {
        return icon ? "success--text text--lighten-4" : "success";
      } else if (
        this.enableText === "Enabled" &&
        this.statusText === "Stopped"
      ) {
        return icon ? "warning--text text--lighten-4" : "warning";
      } else if (
        this.enableText === "Disabled" &&
        this.statusText === "Stopped"
      ) {
        return icon ? "danger--text text--lighten-4" : "danger";
      } else {
        return icon ? "secondary--text text--lighten-4" : "secondary";
      }
    },
    // timeLapse(date) {
    //   return timeAgo.format(new Date(date))
    // }
    timeLocal(ts) {
      return new Date(ts).toLocaleString()
    },
    parseCountIcon(count) {
      if (count >= 0 && count <= 9) return `mdi-numeric-${count}-circle-outline`
      else return `mdi-numeric-9-plus-circle-outline`
    },
    goTo(failed) {
      if ((this.title === 'Video Encoding' || this.title === 'Video Encoding') && failed > 0) {
        this.$router.push(`/app/admin/creatives`)
      }
    }
  },
};
</script>
