<template>
  <o-container ref="container">
    <v-row>
      <v-col
        cols="12"
        class="py-2 text-right"
      >
        <v-btn-toggle
          v-model="viewType"
          borderless
          dense
        >
          <v-btn
            value="chips"
            dense
          >
            <span class="hidden-sm-and-down">CHIPS</span>
            <v-icon right>
              mdi-widgets-outline
            </v-icon>
          </v-btn>

          <v-btn
            value="table"
            dense
          >
            <span class="hidden-sm-and-down">TABLE</span>
            <v-icon right>
              mdi-table
            </v-icon>
          </v-btn>
        </v-btn-toggle>

        <o-button 
          class="pl-5"
          color="primary"
          icon="mdi-sync"
          type="right-label-icon"
          label="Restart Fluent"
          @on-click="restartFluent()"
        />
      </v-col>
      <template v-if="viewType === 'chips'">
        <v-col
          v-for="(group, i) in groups"
          :key="i"
          cols="12"
        >
          <base-card>
            <v-card-title>
              {{ group }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="(subscription, j) in filterByGroup(subscriptions, group)"
                  :key="j"
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6" 
                  lg="3"
                  xl="2"
                >
                  <template v-if="subscription.group === group">
                    <Subscription
                      v-slot="{ data }"
                      :query="subscription.query"
                    >
                      <v-expand-transition>
                        <status-card-v2
                          :key="(data === null) ? null : data.serviceStatus.ts"
                          :title="subscription.name"
                          :enable-text="
                            data === null ? 'Disabled' : data.serviceStatus.active
                          "
                          :status-text="
                            data === null ? 'Stopped' : data.serviceStatus.status
                          "
                          :time-text="data === null ? '' : data.serviceStatus.ts"
                          :queue-id="subscription.id"
                        />
                      </v-expand-transition>
                    </Subscription>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </base-card>
        </v-col>
      </template>
      <template v-if="viewType === 'table'">
        <v-col
          cols="12"
          class=""
        >
          <o-table
            ref="table"
            type="array"
            :data="tableData"
            :headers="[
              {
                text: 'GROUP',
                align: 'start',
                sortable: false,
                value: 'group',
                type: 'text',
              },
              {
                text: 'NAME',
                align: 'start',
                sortable: false,
                value: 'name',
                type: 'text',
              },
              {
                text: 'ACTIVE',
                align: 'middle',
                sortable: false,
                value: 'active',
                type: 'slot',
              },
              {
                text: 'STATUS',
                align: 'middle',
                sortable: false,
                value: 'status',
                type: 'slot',
              },
            ]"
          >
            <template v-slot:slot="row">
              <div v-if="row.prop.key === 'active' || row.prop.key === 'status'">
                <Subscription
                  v-slot="{ data }"
                  :query="row.prop.item.query"
                >
                  <span
                    :class="
                      data &&
                        ['Enabled', 'Running'].includes(
                          data.serviceStatus[row.prop.key]
                        )
                        ? 'text-success'
                        : 'text-warning'
                    "
                  >
                    {{
                      data === null
                        ? "Disabled"
                        : data.serviceStatus[row.prop.key]
                    }}
                  </span>
                </Subscription>
              </div>
            </template>
          </o-table>
        </v-col>
      </template>
    </v-row>
  </o-container>
</template>
<script>
import StatusCardV2 from "@/components/card/StatusCardV2";
import { Subscription } from "vue-gql";
import RestApi from '@/services/RestApi' 

export default {
  name: "DashboardServiceStatus",
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Service Status",
  },
  components: {
    "status-card-v2": StatusCardV2,
    Subscription,
  },
  data() {
    return {
      docState: 'saved',
      hidden: false,
      viewType: "chips",
      subscriptions: [
        {
          group: "FATE Services",
          name: "Location Attribution (Step 1)",
          id: "location_attribution_step1",
          query: `
            subscription {
                serviceStatus (id: "location_attribution_step1") {
                    id,
                    active,
                    status,
                    ts
                }
            }
          `,
        },
        {
          group: "FATE Services",
          name: "Location Attribution (Step 2)",
          id: "location_attribution_step2",
          query: `
            subscription {
                serviceStatus (id: "location_attribution_step2") {
                    id,
                    active,
                    status,
                    ts
                }
            }
          `,
        },
        {
          group: "FATE Services",
          name: "Location Attribution (Step 3)",
          id: "location_attribution_step3",
          query: `
            subscription {
                serviceStatus (id: "location_attribution_step3") {
                    id,
                    active,
                    status,
                    ts
                }
            }
          `,
        },
        {
          group: "FATE Services",
          name: "Location Attribution (Step 4)",
          id: "location_attribution_step4",
          query: `
            subscription {
                serviceStatus (id: "location_attribution_step4") {
                    id,
                    active,
                    status,
                    ts
                }
            }
          `,
        },
        {
          group: "FATE Services",
          name: "Location Attribution (Step 5)",
          id: "location_attribution_step5",
          query: `
            subscription {
                serviceStatus (id: "location_attribution_step5") {
                    id,
                    active,
                    status,
                    ts
                }
            }
          `,
        },
        {
          group: "FATE Services",
          name: "Location Segments (Step 1)",
          id: "location_retargeting_step1",
          query: `
            subscription {
                serviceStatus (id: "location_retargeting_step1") {
                    id,
                    active,
                    status,
                    ts
                }
            }
          `,
        },
        {
          group: "FATE Services",
          name: "Location Segments (Step 2)",
          id: "location_retargeting_step2",
          query: `
            subscription {
                serviceStatus (id: "location_retargeting_step2") {
                    id,
                    active,
                    status,
                    ts
                }
            }
          `,
        },
        {
          group: "FATE Services",
          name: "Location Segments (Step 3)",
          id: "location_retargeting_step3",
          query: `
            subscription {
                serviceStatus (id: "location_retargeting_step3") {
                    id,
                    active,
                    status,
                    ts
                }
            }
          `,
        },
        {
          group: "FATE Services",
          name: "Location Segments (Step 4)",
          id: "location_retargeting_step4",
          query: `
            subscription {
                serviceStatus (id: "location_retargeting_step4") {
                    id,
                    active,
                    status,
                    ts
                }
            }
          `,
        },
        {
          group: "FATE Services",
          name: "Pixel Retargeting",
          id: "pixel_retargeting",
          query: `
            subscription {
              serviceStatus (id: "pixel_retargeting") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
        {
          group: "FATE Services",
          name: "Creative Retargeting",
          id: "creative_retargeting",
          query: `
           subscription {
              serviceStatus (id: "creative_retargeting") {
                  id,
                  active,
                  status,
                  ts
              }
          }
          `,
        },
        {
          group: "DSP Services",
          name: "Video Encoding",
          id: "video_encoding",
          query: `
            subscription {
              serviceStatus (id: "video_encoding") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
        {
          group: "DSP Services",
          name: "Audio Encoding",
          id: "audio_encoding",
          query: `
            subscription {
              serviceStatus (id: "audio_encoding") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
        {
          group: "DSP Services",
          name: "Logs / Stats Syncing",
          id: "logs_sync",
          query: `
            subscription {
              serviceStatus (id: "logs_sync") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
        {
          group: "DSP Services",
          name: "Xandr Audit (Video)",
          id: "creative_approval_video",
          query: `
            subscription {
              serviceStatus (id: "creative_approval_video") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
        {
          group: "DSP Services",
          name: "Xandr Audit (Audio)",
          id: "creative_approval_audio",
          query: `
            subscription {
              serviceStatus (id: "creative_approval_audio") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
        {
          group: "DSP Services",
          name: "Creative VAST (Video)",
          id: "creative_vast_video",
          query: `
            subscription {
              serviceStatus (id: "creative_vast_video") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
        {
          group: "DSP Services",
          name: "Creative VAST Wrapper (Video)",
          id: "creative_vast_wrapper_video",
          query: `
            subscription {
              serviceStatus (id: "creative_vast_wrapper_video") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
        {
          group: "DSP Services",
          name: "Creative VAST Wrapper (Audio)",
          id: "creative_vast_audio_wrapper_video",
          query: `
            subscription {
              serviceStatus (id: "creative_vast_audio_wrapper_video") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
        {
          group: "DSP Services",
          name: "Creative VAST (Audio)",
          id: "creative_vast_audio",
          query: `
            subscription {
              serviceStatus (id: "creative_vast_audio") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
        {
          group: "DSP Services",
          name: "Xandr Audit Check",
          id: "creative_approval_check",
          query: `
            subscription {
              serviceStatus (id: "creative_approval_check") {
                  id,
                  active,
                  status,
                  ts
              }
            }
          `,
        },
      ],
    };
  },
  computed: {
    groups() {
      const output = _.uniq(this.subscriptions.map((d) => d.group));
      return output;
    },
    tableData(paused) {      
      const output = this.subscriptions.map((d) => {
        return { ...d, active: "Disabled", status: "Stopped", paused: (paused?paused:false)};
      }); 
      return output;
    },
  },
  mounted() {},
  methods: {
    queueIcon(queue = 0) {
      if (queue >= 0 && queue <= 9) return `mdi-numeric-${queue}-circle-outline`
      else return `mdi-numeric-9-plus-circle-outline`
    },
    filterByGroup(subs, group) {
      return _.filter(subs, { group: group })
    },
    async restartFluent() {
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You are about to restart fluent service. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            const response = await this.postData(`/rest/intranet/services`, 
               {
                from: "ES Kibana Alerting",
                trigger: "Cron Docker Down",
                severity: "5",
                period_start: (new Date()).toISOString()
            });
            if (response) {
               this.$refs.container.snackBar(`Successfully restarted fluentd service.`, "green",
                  2000
                );
            }
          }
        });
    }
  },
};
</script>